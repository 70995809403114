import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { IMemberGroup, MemberGroupFormProps } from '../../../models/IMemberGroups';

const MemberGroupForm: React.FC<MemberGroupFormProps> = ({ memberGroupForm, onSave, onCancel, isLoading }) => {
  const [form, setForm] = useState<IMemberGroup>(memberGroupForm);
  const [userIDs, setUserIDs] = useState('');  

  useEffect(() => {
    setForm(memberGroupForm);
  }, [memberGroupForm]);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const updatedForm = {
      ...form,
      user_ids: userIDs.split(',').map(id => parseInt(id.trim(), 10)),
    };
    onSave(updatedForm);
  };

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Member Group Name"
        name="name"
        value={form.name}
        onChange={handleChange}
        required
        fullWidth
        size="small"
      />
      <TextField
        label="Description"
        name="description"
        value={form.description}
        onChange={handleChange}
        fullWidth
        size="small"
      />

      <TextField
        label="User IDs"
        name="user_ids"
        value={userIDs}
        onChange={(e) => setUserIDs(e.target.value)}
        fullWidth
        size="small"
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Add
        </Button>
      </Box>
    </Box>
  );
};

export default MemberGroupForm;