// src/helpers/CustomAlert.tsx
import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

interface CustomAlertProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ open, message, severity, onClose }) => {
  let icon;

  switch (severity) {
    case 'success':
      icon = <CheckIcon fontSize="inherit" />;
      break;
    case 'error':
      icon = <ErrorIcon fontSize="inherit" />;
      break;
    case 'info':
      icon = <InfoIcon fontSize="inherit" />;
      break;
    case 'warning':
      icon = <WarningIcon fontSize="inherit" />;
      break;
    default:
      icon = null;
  }

  return (
    <Snackbar 
    open={open}
    autoHideDuration={3000} 
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
      <Alert onClose={onClose} severity={severity} icon={icon}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;