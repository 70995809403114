import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { IRewardPoint } from '../../../models/IRewardPoint';
import RewardPointForm from '../../common/RewardPointForm/RewardPointForm';
import { toast } from '../../common/SnackBar/snackBar';
import { createRewardPointsAPI } from '../../../api/RewardPointsAPI';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const defaultRewardPoint: IRewardPoint = {
    id: null,
    user_id: null,
    points: 0,
    description: null,
    balance: null,
    order_id: null,
    type: 'debit',
    transaction_at: null,
};
  
const CreateRewardPointPage: React.FC = () => {
  const [rewardPointForm] = useState<IRewardPoint>(defaultRewardPoint);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = async (rewardPoint: IRewardPoint) => {
    setIsLoading(true);
    try {
      const NewRewardPoint = {
        ...rewardPoint,
        transaction_at: new Date(), // Set to current date/time
      };
      await createRewardPointsAPI(NewRewardPoint);
      toast.success('Reward point created successfully');
      navigate('/Marketing/RewardPoint');
    } catch (error) {
      toast.error('Error creating reward point');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/Marketing/RewardPoint');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      
      <h2>Create Reward Point</h2>
      <Divider sx={{ my: 2 }} />
      <RewardPointForm 
        rewardPointForm={rewardPointForm} 
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CreateRewardPointPage;