import React, { useState, useEffect } from 'react';
import {Box,Grid,TextField,Typography,FormControlLabel,Checkbox,FormControl,Select,MenuItem,InputLabel,SelectChangeEvent} from '@mui/material';
import { PATH_NAME } from '../../../configs/pathName';
import useSWR from 'swr';
import { IMemberGroupCoupon, VoucherMemberGroupProps } from '../../../models/IMemberGroupCoupon';
import { ICouponDefinitions } from '../../../models/IVoucher';
import { getCoupOnDefinitionAPI } from '../../../api/CouponDefinitionAPI';
import { getMemberGroupAPI } from '../../../api/MemberGroupAPI';

const VoucherMemberGroup: React.FC<VoucherMemberGroupProps> = ({
  memberGroupCouponForm,
  onFormChange,
}) => {
  const [memberGroupCoupon, setMemberGroupCoupon] = useState<IMemberGroupCoupon>(memberGroupCouponForm);
  const [selectedCouponDefinition, setSelectedCouponDefinition] = useState<number | ''>(memberGroupCouponForm.coupon_definition_id || '');
  const [selectedMemberGroupId, setSelectedMemberGroupId] = useState<number | ''>(memberGroupCouponForm.member_group_id || ''); // New state for member group ID

  const { data: couponDefinitionsData } = useSWR(PATH_NAME.API_ADMIN_COUPON_DEFINITION, getCoupOnDefinitionAPI);
  const { data: memberGroupsData } = useSWR(PATH_NAME.API_ADMIN_MEMBER_GROUPS, getMemberGroupAPI);

  useEffect(() => {
    setMemberGroupCoupon(memberGroupCouponForm);
    setSelectedCouponDefinition(memberGroupCouponForm.coupon_definition_id || '');
    setSelectedMemberGroupId(memberGroupCouponForm.member_group_id || '');
  }, [memberGroupCouponForm]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const updatedMemberGroupCoupon = {
      ...memberGroupCoupon,
      [name]: value,
    };
    setMemberGroupCoupon(updatedMemberGroupCoupon);
    onFormChange(updatedMemberGroupCoupon);
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setSelectedCouponDefinition(value);
    const updatedMemberGroupCoupon = {
      ...memberGroupCoupon,
      coupon_definition_id: value,
    };
    setMemberGroupCoupon(updatedMemberGroupCoupon);
    onFormChange(updatedMemberGroupCoupon);
  };

  const handleMemberGroupChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setSelectedMemberGroupId(value);
    const updatedMemberGroupCoupon = {
      ...memberGroupCoupon,
      member_group_id: value,
    };
    setMemberGroupCoupon(updatedMemberGroupCoupon);
    onFormChange(updatedMemberGroupCoupon);
  };

  const couponList = Array.isArray(couponDefinitionsData) ? couponDefinitionsData : [];
  const memberGroupsList = Array.isArray(memberGroupsData) ? memberGroupsData : [];

  const periodTypes = ['all', 'year', 'month', 'week', 'day'];

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
      <Grid item xs={2.2} container justifyContent="flex-end">
        <Typography variant="subtitle2" fontWeight="bold">Coupon Definition</Typography>
      </Grid>
      <Grid item xs={9.8}>
        <FormControl fullWidth margin="normal" size="small">
          <InputLabel id="coupon-definition-label">Coupon Definition</InputLabel>
          <Select
            labelId="coupon-definition-label"
            label="Coupon Definition"
            value={selectedCouponDefinition}
            onChange={handleSelectChange}
          >
            {couponList.length > 0 ? (
              couponList.map((couponDefinition: ICouponDefinitions) => (
                <MenuItem key={couponDefinition.id} value={couponDefinition.id ?? ''}>
                  {couponDefinition.name} (ID: {couponDefinition.id})
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No coupon definitions available</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>

    <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Member Group</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="member-group-label">Member Group</InputLabel>
            <Select
              labelId="member-group-label"
              label="Member Group"
              value={selectedMemberGroupId}
              onChange={handleMemberGroupChange}
            >
              {memberGroupsList.length > 0 ? (
                memberGroupsList.map((memberGroup) => (
                  <MenuItem key={memberGroup.id} value={memberGroup.id}>
                    {memberGroup.name} (ID: {memberGroup.id})
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No member groups available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Coupon Name</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <TextField
            name="name"
            label="Coupon Name"
            value={memberGroupCoupon.name || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Start Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date_start"
            label="Start Date"
            type="date"
            value={memberGroupCoupon.date_start || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">End Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date_end"
            label="End Date"
            type="date"
            value={memberGroupCoupon.date_end || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Apply to All Machines</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <FormControlLabel
            control={
              <Checkbox
                name="all_machines"
                checked={memberGroupCoupon.all_machines || false}
                onChange={(e) => setMemberGroupCoupon({
                  ...memberGroupCoupon,
                  all_machines: e.target.checked
                })}
              />
            }
            label="All Machines"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">User Redemption Limit</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <TextField
            name="user_redemption_period_limit"
            label="Redemption Limit"
            type="number"
            value={memberGroupCoupon.user_redemption_period_limit || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">User Redemption Period Type</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="user-redemption-period-type-label">Redemption Period Type</InputLabel>
            <Select
              labelId="user-redemption-period-type-label"
              size="small"
              value={memberGroupCoupon.user_redemption_period_type || ''}
              onChange={(e: SelectChangeEvent<string>) =>
                setMemberGroupCoupon({
                  ...memberGroupCoupon,
                  user_redemption_period_type: e.target.value,
                })
              }
              label="Redemption Period Type"
            >
              {periodTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Group Redemption Limit</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <TextField
            name="group_redemption_period_limit"
            label="Group Redemption Limit"
            type="number"
            value={memberGroupCoupon.group_redemption_period_limit || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2.2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Group Redemption Period Type</Typography>
        </Grid>
        <Grid item xs={9.8}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="group-redemption-period-type-label">Group Redemption Period Type</InputLabel>
            <Select
              labelId="group-redemption-period-type-label"
              value={memberGroupCoupon.group_redemption_period_type || ''}
              onChange={(e: SelectChangeEvent<string>) =>
                setMemberGroupCoupon({
                  ...memberGroupCoupon,
                  group_redemption_period_type: e.target.value,
                })
              }
              label="Group Redemption Period Type"
            >
              {periodTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VoucherMemberGroup;