import React from "react";
import { Avatar, List, Stack, Toolbar, Drawer } from "@mui/material";
import sizeConfigs from "../../../configs/sizeConfigs";
import colorConfigs from "../../../configs/colorConfigs";
import assets from "../../../assets";
import appRoutes from "../../../routes/appRoutes";
import SideBarItemCollapse from "./SideBarItemCollapse";
import SideBarItem from "./SideBarItem";

interface SideBarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ mobileOpen, handleDrawerToggle }) => {
  const drawer = (
    <List disablePadding>
      <Toolbar sx={{ marginBottom: "40px" }}>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="center"
        >
          <Avatar
            src={assets.images.logo}
            sx={{
              width: "100px",
              height: "auto",
              borderRadius: "0px",
              paddingTop: "40px",
            }}
          />
        </Stack>
      </Toolbar>
      {appRoutes.map((route, index) => (
        route.sidebarProps ? (
          route.child ? (
            <SideBarItemCollapse item={route} key={index} />
          ) : (
            <SideBarItem item={route} key={index} />
          )
        ) : null
      ))}
    </List>
  );

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sizeConfigs.sidebar.width,
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sizeConfigs.sidebar.width,
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </nav>
    
  );
};

export default SideBar;
