import axiosInstance from './axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';

export const getUserWalletInfo = async (user_id: number) => {
    try {
        const response = await axiosInstance.get(PATH_NAME.API_ADMIN_USER_GET_USER_WALLET_INFO(user_id));
        if (response.data && response.data.result !== undefined) {
            return response.data.result;
        } else {
            console.error('Invalid response structure:', response.data);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        handleError(error, 'fetching User Wallet Info');
        throw new Error('Failed to fetch User Wallet Info');
    }
  };