import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../common/DataTable/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useSWR, { useSWRConfig } from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { generalFetcher, generalItemFetcher } from '../../../../helpers/Fetchers/fetchers';
import { deleteMemberGroupCouponAPI } from '../../../../api/MemberGroupCouponAPI';

const VoucherMemberGroupTable: React.FC = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);

  const { data: profile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: memberGroupCoupons, error, isLoading } = useSWR(
    profile ? PATH_NAME.API_MEMBER_GROUP_COUPONS : null,
    generalItemFetcher
  );

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Coupon Name', width: 150 },
    { field: 'date_start', headerName: 'Start Date', width: 150 },
    { field: 'date_end', headerName: 'End Date', width: 150 },
    { field: 'all_machines', headerName: 'All Machines', type: 'boolean', width: 150 },
    { field: 'user_redemption_period_limit', headerName: 'Redemption Limit', width: 150 },
    { field: 'user_redemption_period_type', headerName: 'Redemption Period Type', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (id: number) => {
    setSelectedCouponId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedCouponId !== null) {
      try {
        await deleteMemberGroupCouponAPI(selectedCouponId);
        await mutate(PATH_NAME.API_MEMBER_GROUP_COUPONS);
      } catch (error) {
        console.error('Failed to delete member group coupon:', error);
      }
    }
    setOpen(false);
    setSelectedCouponId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCouponId(null);
  };

  const handleEdit = (id: number) => {
    navigate(`/Marketing/Vouchers/EditMemberGroupCoupons/${id}`);
  };

  const handleCreate = () => {
    navigate('/Marketing/Vouchers/CreateMemberGroupCoupons');
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading member group coupons</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={memberGroupCoupons || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this member group coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VoucherMemberGroupTable;
