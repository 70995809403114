import React, { useState, useEffect } from 'react';
import MemberGroupForm from '../../../common/MemberGroupForm/MemberGroupForm';
import MemberGroupLayoutPage from './MemberGroupLayoutPage';
import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { IMemberGroup } from '../../../../models/IMemberGroups';
import { toast } from '../../../common/SnackBar/snackBar';
import { getMemberGroupByIdAPI, updateMemberGroupsAPI } from '../../../../api/MemberGroupAPI';

const EditMemberGroupPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();

  const { data: memberGroup, error } = useSWR(
    id ? `${PATH_NAME.API_ADMIN_MEMBER_GROUPS_BY_ID(id)}` : null,
    () => getMemberGroupByIdAPI(id!).then((response) => {
      return response;
    })
  );
   
  const [memberGroupForm, setMemberGroupForm] = useState<IMemberGroup>({
    id: 0,
    name: '',
    description: '',
    referralCode: '',
    user_Ids: [],
    created_at: '',
    updated_at: '',
    tenant_id: 0,
    slug: '',
    is_active: true,
    is_global: false,
    is_deleted: false,
    deleted_at: null,
    created_by: 0,
    updated_by: 0,
    deleted_by: null,
  });

  useEffect(() => {
    if (memberGroup) {
      setMemberGroupForm(memberGroup);
    }
  }, [memberGroup]);

  const handleSave = async (memberGroup: IMemberGroup) => {
    setIsLoading(true);
    try {
      await updateMemberGroupsAPI(memberGroup.id.toString(), memberGroup);
      toast.success('Member group updated successfully');
      navigate('/System/MemberGroups');
    } catch (error) {
      toast.error('Error updating member group');
    }
  };

  const handleCancel = () => {
    navigate('/System/MemberGroup');
  };

  const title = 'Edit Member Group';
  const backLink = '/System/MemberGroups';

  const tabs = [
    {
      label: 'General',
      content: (
        <MemberGroupForm
          memberGroupForm={memberGroupForm}
          onSave={handleSave}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      ),
    },
  ];

  if (error) {
    return <div>Error loading member group data</div>;
  }

  return (
    <div>
      <MemberGroupLayoutPage title={title} backLink={backLink} tabs={tabs} />
    </div>
  );
};

export default EditMemberGroupPage;