import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import VoucherMemberGroup from '../../../common/VoucherForm/VoucherMemberGroupForm';
import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { toast } from '../../../common/SnackBar/snackBar';
import { getMemberGroupCouponByIdAPI, updateMemberGroupCouponAPI } from '../../../../api/MemberGroupCouponAPI'; // Ensure this import is correct
import { IMemberGroupCoupon } from '../../../../models/IMemberGroupCoupon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EditMemberGroupCoupon: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { data: memberGroupCoupon, error } = useSWR(
    id ? `${PATH_NAME.API_ADMIN_MEMBER_GROUP_COUPONS_BY_ID(id)}` : null,
    () => getMemberGroupCouponByIdAPI(id!)
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [memberGroupCouponForm, setMemberGroupCouponForm] = useState<IMemberGroupCoupon>({
    id: 0,
    is_deleted: false,
    member_group_id: 0,
    coupon_definition_id: 0,
    name: '',
    date_start: '',
    date_end: '',
    all_machines: false,
    applicable_machines: [],
    user_redemption_period_limit: 0,
    user_redemption_period_type: '',
    group_redemption_period_limit: 0,
    group_redemption_period_type: '',
  });

  useEffect(() => {
    if (memberGroupCoupon) {
      setMemberGroupCouponForm(memberGroupCoupon);
    }
  }, [memberGroupCoupon]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: IMemberGroupCoupon) => {
    setMemberGroupCouponForm(updatedForm);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await updateMemberGroupCouponAPI(memberGroupCouponForm.id.toString(), memberGroupCouponForm);
      toast.success('Member Group Coupon updated successfully');
      navigate('/Marketing/MemberGroupCoupons');
    } catch (error) {
      toast.error('Error updating member group coupon');
    }
  };

  const handleCancel = () => {
    navigate('/Marketing/MemberGroupCoupons');
  };

  if (error) {
    return <div>Error loading member group coupon data</div>;
  }

  return (
    <Box>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      
      <h2>Edit Member Group Coupon</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Member Group Coupon Tabs">
        <Tab label="Member Groups" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherMemberGroup
            memberGroupCouponForm={memberGroupCouponForm}
            onFormChange={handleFormChange}
            onCancel={handleCancel}
            isLoading={isLoading}  
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditMemberGroupCoupon;
