import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../common/DataTable/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useSWR, { useSWRConfig } from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { generalFetcher, generalItemFetcher } from '../../../../helpers/Fetchers/fetchers';
import { deleteMemberGroupsAPI } from '../../../../api/MemberGroupAPI';

const MemberGroupTable: React.FC = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  
  const { data: profile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: memberGroups, error, isLoading } = useSWR(profile ? PATH_NAME.API_ADMIN_MEMBER_GROUPS : null, generalItemFetcher);

  const [open, setOpen] = useState(false);
  const [selectedMemberGroupsId, setSelectedMemberGroupsId] = useState<number | null>(null);

  const columns = [
    { field: 'name', headerName: 'Member Groups Name', flex: 9 },
    {
      field: 'actions', headerName: 'Actions', flex: 1, renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id: string) => {
    navigate(`/System/MemberGroups/EditMemberGroups/${id}`);
  };

  const handleCreate = () => {
    navigate('/System/MemberGroups/AddMemberGroups');
  };

  const handleDeleteClick = (id: number) => {
    setSelectedMemberGroupsId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedMemberGroupsId !== null) {
      try {
        await deleteMemberGroupsAPI(selectedMemberGroupsId);
        await mutate(PATH_NAME.API_ADMIN_MEMBER_GROUPS);
      } catch (error) {
        console.error('Failed to delete member group:', error);
      }
    }
    setOpen(false);
    setSelectedMemberGroupsId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMemberGroupsId(null);
  };
  
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Member Groups</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={memberGroups || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this member group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MemberGroupTable;