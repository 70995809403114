import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getUserCouponsAdmin } from '../../../../../api/UserCouponAPI';
import { IGetUserCoupon } from '../../../../../models/IUserCoupon';

const UserCoupons: React.FC<{ userId: number }> = ({ userId }) => {
  const [coupons, setCoupons] = useState<IGetUserCoupon[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        setLoading(true);
        const data = await getUserCouponsAdmin(userId);
        setCoupons(data.coupons); 
      } catch (error) {
        setError(`Failed to fetch coupons for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [userId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="user coupons table">
        <TableHead>
          <TableRow>
            <TableCell>Coupon ID</TableCell>
            <TableCell align="right">Coupon Name</TableCell>
            <TableCell align="right">Discount Type</TableCell>
            <TableCell align="right">Discount Amount</TableCell>
            <TableCell align="right">Target Type</TableCell>
            <TableCell align="right">Target Skus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coupons && coupons.length > 0 ? (
            coupons.map((coupon) => (
              <TableRow key={coupon.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{coupon.id}</TableCell>
                <TableCell align="right">{coupon.name || 'N/A'}</TableCell>
                <TableCell align="right">{coupon.discount_type}</TableCell>
                <TableCell align="right">{coupon.discount_amount}</TableCell>
                <TableCell align="right">{coupon.target_type}</TableCell>
                <TableCell align="right">{coupon.target_skus?.join(', ') || 'N/A'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No coupons found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserCoupons;
