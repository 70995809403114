import React, { useState } from "react";
import { Box, CssBaseline, Toolbar, Drawer, Hidden } from "@mui/material";
import TopBar from "../common/TopBar/TopBar";
import sizeConfigs from "../../configs/sizeConfigs";
import SideBar from "../common/SideBar/SideBar";
import colorConfigs from "../../configs/colorConfigs";
import { Outlet } from "react-router-dom";

const MainLayout: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <TopBar onMenuClick={handleSidebarToggle} />
      <Box
        component="nav"
        sx={{
          width: { sm: sizeConfigs.sidebar.width },
          flexShrink: { sm: 0 },
        }}
      >
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={isSidebarOpen}
            onClose={handleSidebarToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: sizeConfigs.sidebar.width,
                boxSizing: "border-box",
                borderRight: "0px",
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.color,
              },
            }}
          >
            <SideBar mobileOpen={isSidebarOpen} handleDrawerToggle={handleSidebarToggle} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            sx={{
              "& .MuiDrawer-paper": {
                width: sizeConfigs.sidebar.width,
                boxSizing: "border-box",
                borderRight: "0px",
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.color,
              },
            }}
          >
            <SideBar mobileOpen={false} handleDrawerToggle={handleSidebarToggle} />
          </Drawer>
        </Hidden>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${sizeConfigs.sidebar.width})` },
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
