import { createAsyncThunk } from "@reduxjs/toolkit";
import { INewUser, IUser } from "../../../models/IUser";
import { Register as RegisterAPI, Login as LoginAPI, UserProfile as UserProfileAPI } from "../../../api/AuthAPI";

export const login = createAsyncThunk("login", async (data: IUser, { rejectWithValue }) => {
  try {
    const resData = await LoginAPI(data); 
    if (resData.success && resData.access_token) {
      localStorage.setItem("token", resData.access_token);

      const resUserData = await UserProfileAPI();
      if (resUserData.success && resUserData.result) {
        localStorage.setItem("userInfo", JSON.stringify(resUserData.result));
        return resUserData.result;
      }
    } else {
      return rejectWithValue('Failed to fetch user profile');
    }
  } catch (error:any) {
    return rejectWithValue("Login failed");
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    return true;
  } catch (error) {
    return rejectWithValue('Failed to clear user data');
  }
});

export const register = createAsyncThunk("register", async (data: INewUser, { rejectWithValue }) => {
  try {
    const resData = await RegisterAPI(data);
    if (resData.success && resData.result.access_token) {
      const token = resData.result.access_token;
      localStorage.setItem("token", token);

      const resUserData = await UserProfileAPI();
      if (resUserData.success && resUserData.result) {
        localStorage.setItem("userInfo", JSON.stringify(resUserData.result));
        return resUserData.result;
      } else {
        return rejectWithValue(resUserData.error_message || 'Failed to fetch user profile');
      }
    } else {
      return rejectWithValue(resData.error_message || 'Registration failed');
    }
  } catch (error: any) {
    console.error('Error in register thunk:', error);
    return rejectWithValue(error.message || 'Registration failed');
  }
});