import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card } from '@mui/material';
import dayjs from 'dayjs';
import { getRewardPointBalance, getUserRewardsAPI } from '../../../../api/RewardPointsAPI';
import { UserRewardsProps, IReward } from '../../../../models/IRewardPoint';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';

const UserRewards: React.FC<UserRewardsProps> = ({ userId }) => {
  const [rewards, setRewards] = useState<IReward[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { data: balance } = useSWR(
    userId ? PATH_NAME.API_GET_USER_REWARD_POINT_BALANCE(userId) : null,
    () => getRewardPointBalance(userId)
  );

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        setLoading(true);
        const data = await getUserRewardsAPI(userId);
        setRewards(data.result);
      } catch (error) {
        setError(`Failed to fetch rewards for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, [userId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Card sx={{ p: 3, textAlign: 'center', backgroundColor: '#ffffff', borderRadius: 2, mb: 2 }}>
        <Typography variant="body1" color="textSecondary">User Balance</Typography>
        <Typography variant="h6" color="#4CAF50" fontWeight="bold">
          {balance}
        </Typography>
        <Typography variant="body2" color="textSecondary">Points</Typography>
      </Card>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="user rewards table">
        <TableHead>
          <TableRow>
            <TableCell>Reward ID</TableCell>
            <TableCell align="right">Points</TableCell>
            <TableCell align="right">Balance</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Transaction Date</TableCell>
            <TableCell align="right">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards && rewards.length > 0 ? (
            rewards.map((reward) => (
              <TableRow key={reward.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{reward.id}</TableCell>
                <TableCell align="right">{reward.points}</TableCell>
                <TableCell align="right">{reward.balance}</TableCell>
                <TableCell align="right">{reward.type}</TableCell>
                <TableCell align="right">{dayjs(reward.transaction_at).format('DD-MM-YYYY HH:mm')}</TableCell>
                <TableCell align="right">{reward.description || 'N/A'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No rewards found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserRewards;
