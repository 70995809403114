import { PATH_NAME } from '../configs/pathName';
import axiosInstance from '../api/axiosInstance';
import { IRole, IRoleAPIResponse } from '../models/IRole';
import { handleError } from '../helpers/Fetchers/fetchers';

export const fetchRolesAPI = async (): Promise<IRoleAPIResponse> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ROLES);
    return response.data;
  } catch (error) {
    handleError(error, 'fetching roles');
    throw error;
  }
};

export const getRoleByIdAPI = async (id: string): Promise<IRole> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_ROLES_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch role');
    }
  } catch (error) {
    handleError(error, `fetching role with ID ${id}`);
    throw error;
  }
};

export const createRoleAPI = async (role: Partial<IRole>) => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_ROLES, role);
    return response.data;
  } catch (error) {
    handleError(error, 'creating role');
    throw error;
  }
};

export const updateRoleAPI = async (role: IRole): Promise<IRole> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_ROLES_BY_ID(role.id.toString()), role);
    return response.data;
  } catch (error) {
    handleError(error, 'updating role');
    throw error;
  }
};

export const deleteRoleAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_ROLES_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, `deleting role with ID ${id}`);
    throw error;
  }
};

export const fetchPermissionsAPI = async () => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_PERMISSIONS);
    return response.data.result.items;
  } catch (error) {
    handleError(error, 'fetching permissions');
    throw error;
  }
};