import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface YearPickerProps {
    value: Dayjs | null;
    onChange: (date: Dayjs | null) => void;
    label: string;
}

const YearPicker: React.FC<YearPickerProps> = ({ value, onChange, label }) => {
    const currentYear = dayjs().year();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                views={['year']}
                label={label}
                value={value}
                onChange={onChange}
                sx={{ mb: 2, mt: 2 }}
                maxDate={dayjs().year(currentYear)}
            />
        </LocalizationProvider>
    );
};

export default YearPicker;
