import { colors } from "@mui/material";

const colorConfigs = {
    sidebar: {
        bg: "#233044",
        color: "#eeeeee",
        hoverBg: "#1e293a",
        activeBg: "#1e293a"
    },
    topbar: {
        bg: "#fff",
        color: "#000"
    },
    mainBg: colors.grey["100"]
};

export default colorConfigs;

