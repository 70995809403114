import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import { IUserCoupon } from '../../../../models/IUserCoupon';
import VoucherUserCoupon from '../../../common/VoucherForm/VoucherUserCouponForm';
import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { toast } from '../../../common/SnackBar/snackBar';
import { getUserCouponByIdAPI, updateUserCouponAPI } from '../../../../api/UserCouponAPI';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EditUserCoupon: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { data: userCoupon, error } = useSWR(
    id ? `${PATH_NAME.API_ADMIN_USER_COUPONS_BY_ID(id)}` : null,
    () => getUserCouponByIdAPI(id!)
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [userCouponForm, setUserCouponForm] = useState<IUserCoupon>({
    id: 0,
    user_id: 0,
    coupon_definition_id: 0,
    name: '',
    date_start: '',
    date_end: '',
    all_machines: false,
    applicable_machines: [],
    user_redemption_period_limit: 0,
    user_redemption_period_type: '',
  });

  useEffect(() => {
    if (userCoupon) {
      setUserCouponForm(userCoupon);
    }
  }, [userCoupon]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: IUserCoupon) => {
    setUserCouponForm(updatedForm);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await updateUserCouponAPI(userCouponForm.id.toString(), userCouponForm);
      toast.success('User Coupon updated successfully');
      navigate('/Marketing/UserCoupons');
    } catch (error) {
      toast.error('Error updating user coupon');
    }
  };

  const handleCancel = () => {
    navigate('/Marketing/UserCoupons');
  };

  if (error) {
    return <div>Error loading user coupon data</div>;
  }

  return (
    <Box>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      
      <h2>Edit User Coupon</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="User Coupon Tabs">
        <Tab label="User Coupons" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherUserCoupon
            userCouponForm={userCouponForm}
            onFormChange={handleFormChange}
            isLoading={isLoading}  
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditUserCoupon;