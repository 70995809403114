import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VoucherUserCoupon from '../../../common/VoucherForm/VoucherUserCouponForm';
import { IUserCoupon } from '../../../../models/IUserCoupon';
import { createUserCouponAPI } from '../../../../api/UserCouponAPI';
import { toast } from '../../../common/SnackBar/snackBar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CreateUserCoupon: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userCouponForm, setUserCouponForm] = useState<IUserCoupon>({
    id: 0,
    user_id: 0,
    coupon_definition_id: 0,
    name: '',
    date_start: '',
    date_end: '',
    all_machines: false,
    applicable_machines: [],
    user_redemption_period_limit: 0,
    user_redemption_period_type: '',
  });

  const [userCouponItem, setUserCouponItem] = useState<IUserCoupon>(userCouponForm);

  const navigate = useNavigate();

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: IUserCoupon) => {
    setUserCouponForm(updatedForm);
  };

  const handleCancel = () => {
    navigate('/Marketing/UserCoupons');
  };

  useEffect(() => {
    setUserCouponItem(userCouponForm);
  }, [userCouponForm]);
  
  const handleSave = async () => {
    setIsLoading(true);
    if (!userCouponItem.user_id) {
      toast.error('User ID is required');
      return;
    }

    const userCouponToSave = {
      ...userCouponItem,
    };

    try {
      await createUserCouponAPI(userCouponToSave);
      toast.success('Voucher created successfully');
      navigate('/Marketing/UserCoupons');
    } catch (error) {
      toast.error('Error creating voucher');
    }
  };
  
  return (
    <Box>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      
      <h2>Create User Coupons</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="User Coupon Tabs">
        <Tab label="User Coupons" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherUserCoupon
            userCouponForm={userCouponForm}
            onFormChange={handleFormChange}
            isLoading={isLoading}  
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUserCoupon;
