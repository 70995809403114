import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography } from '@mui/material';
import { UserAdjustingInputField, iniUserAdjustingInputField } from './UserAdjustmentDto';

interface UserLayoutPageProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateAdjustWallet: (inputField: UserAdjustingInputField) => void
}

const UserAdjustWallet: React.FC<UserLayoutPageProps> = ({
    open,
    setOpen,
    updateAdjustWallet
}) => {
    const [inputField, setInputField] = React.useState(iniUserAdjustingInputField());


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const _confirmUpdate = () => {
        updateAdjustWallet(inputField)
        setInputField(iniUserAdjustingInputField())
        setOpen(false)

    }

    const _cancelUpdate = () => {
        setInputField(iniUserAdjustingInputField())
        setOpen(false)
    }

    const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.addEventListener('wheel', handleWheel as EventListener); // Attach wheel event on focus
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.removeEventListener('wheel', handleWheel as EventListener); // Remove wheel event on blur
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>{" Adjust Wallet"}</DialogTitle>
            <DialogContent>
                <Typography variant="body2" gutterBottom>
                    Please put positive number to add to wallet and negative number to deduct from wallet
                </Typography>
                <TextField
                    name="description"
                    label="Description"
                    value={inputField.description}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="string"
                    style={{ width: '100%' }}
                />
                <TextField
                    name="amount"
                    label="Amount"
                    value={inputField.amount}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ width: '100%' }}
                    onFocus={handleFocus} // Add focus handler
                    onBlur={handleBlur}   // Add blur handler
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => _cancelUpdate()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => _confirmUpdate()} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default UserAdjustWallet;


