import { Container, Typography, Box, Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from 'swr';
import { IOrderItem, IPayment, IOrder } from "../../../../models/IOrders";
import { orderDetailstyle } from "../../../styles/OrderDetails";
import { deductCreditCardAPI, deductWalletAPI, getOrderById } from "../../../../api/OrderAPI";
import { useState } from "react";
import AdjustWallet from "./AdjustWallet";
import AdjustCreditCard from "./AdjustCreditCard";
import { toast } from "../../../common/SnackBar/snackBar";
import { OrderAdjustingInputField } from "./OrderAdjustmentDto";
import { IUserBasicInfo } from "../../../../models/IUser";
import { getUserByIdAPI } from "../../../../api/UserAPI";
import { useNavigate } from 'react-router-dom';

const formatBalance = (balance: number | undefined) => {
  if (balance === undefined) return '$0.00';
  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  });
  return balance < 0 ? `-${formatter.format(Math.abs(balance))}` : formatter.format(balance);
};

const OrderDetailsPage = () => {
  const { orderId } = useParams<{ orderId?: string }>();
  const [openAdjustWallet, setOpenAdjustWallet] = useState(false);
  const [openAdjustCreditCard, setOpenAdjustCreditCard] = useState(false);

  const navigate = useNavigate();

  const { data: orderDetails, error: orderError } = useSWR<IOrder>(
    orderId ? ['getOrderById', orderId] : null,
    orderId ? () => getOrderById(orderId) : null
  );

  const { data: userDetails, error: userError } = useSWR<IUserBasicInfo>(
    orderDetails ? ['getUserById', orderDetails.user_id] : null,
    orderDetails ? () => getUserByIdAPI(orderDetails.user_id.toString()) : null
  );
  
  if (orderError || userError) return <div>Error loading order or user details</div>;
  if (!orderDetails || !userDetails) return <div>Loading order and user details...</div>;

  const {
    subtotal = 0,
    payment_amount = 0,
    items = [],
    payments = [],
  } = orderDetails;

  const userFirstName = userDetails?.firstname;
  const userLastName = userDetails?.lastname;
  const userMobile = userDetails?.full_mobile;

  const _updateAdjustWallet = async (inputField: OrderAdjustingInputField) => {
    const payload = {
      customer_id: orderDetails.user_id,
      order_id: orderDetails.id,
      amount: inputField.amount,
      description: inputField.description,
    };
    try {
      const response = await deductWalletAPI(payload);
      toast.success("Adjust Wallet successful!");
      console.log('Wallet deduction response:', response);
    } catch (error) {
      toast.error("Adjust Wallet Fail!");
      console.error('Error deducting wallet:', error);
    }
  };

  const _updateAdjustCreditCard = async (inputField: OrderAdjustingInputField) => {
    const payload = {
      customer_id: orderDetails.user_id,
      order_id: orderDetails.id,
      amount: inputField.amount,
      description: inputField.description,
    };
    try {
      const response = await deductCreditCardAPI(payload);
      toast.success("Adjust Credit Card successful!");
      console.log('Credit card deduction response:', response);
    } catch (error) {
      toast.error("Adjust Credit Card Fail!");
      console.error('Error deducting credit card:', error);
    }
  };

  const handleViewClick = (id: string) => {
    navigate(`/users/user_details/${Number(id)}`);
  };

  return (
    <>
      <Container sx={orderDetailstyle.container}>
        <Typography variant="h6" sx={orderDetailstyle.title}>ORDER DETAILS</Typography>

        <Box style={{ marginBottom: '20px', textAlign: 'left' }} sx={orderDetailstyle.summaryBox}>
          <Typography variant="h6">User Details</Typography>
          {userDetails && (
            <Box>
              <Typography>Name: {userFirstName} {userLastName}</Typography>
              <Typography>Email: {userDetails.email}</Typography>
              <Typography>Mobile: {userMobile}</Typography>
            </Box>
          )}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleViewClick(userDetails?.id)} 
            sx={{ marginTop: '10px' }}
          >
            View User Details
          </Button>
        </Box>

        <Box sx={orderDetailstyle.summaryBox}>
          <Box sx={orderDetailstyle.headerRow}>
            <Typography sx={orderDetailstyle.headerText}>Product</Typography>
            <Typography sx={orderDetailstyle.headerText}>Total</Typography>
          </Box>

          {items.length > 0 ? (
            items.map((item: IOrderItem) => (
              <Box key={item.sku_id} sx={orderDetailstyle.itemRow}>
                <Box sx={orderDetailstyle.itemDetails}>
                  <Typography sx={orderDetailstyle.itemName}>{item.sku_name}</Typography>
                  <Typography sx={orderDetailstyle.itemName}>{item.sku_sku}</Typography>
                  <Typography sx={orderDetailstyle.itemQuantity}>× {item.quantity}</Typography>
                </Box>
                <Typography sx={orderDetailstyle.itemPrice}>{formatBalance(item.item_total)}</Typography>
              </Box>
            ))
          ) : (
            <Typography sx={orderDetailstyle.noItemsText}>No items available for this order.</Typography>
          )}

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.subtotalRow}>
            <Typography sx={orderDetailstyle.subtotalText}>Subtotal:</Typography>
            <Typography sx={orderDetailstyle.subtotalText}>{formatBalance(subtotal)}</Typography>
          </Box>

          <Box sx={orderDetailstyle.paymentRow}>
            <Typography sx={orderDetailstyle.paymentLabel}>Payment method:</Typography>
            <Typography sx={orderDetailstyle.paymentText}>
              {payments.length > 0 ? (
                payments.map((payment: IPayment, index: number) => (
                  <span key={index}>
                    {payment.type}{index < payments.length - 1 ? ', ' : ''}
                  </span>
                ))
              ) : (
                'N/A'
              )}
            </Typography>
          </Box>

          <Box sx={orderDetailstyle.paymentBreakdownBox}>
            {payments.length > 0 ? (
              payments.map((payment: IPayment, index: number) => (
                <Box key={index} sx={orderDetailstyle.paymentBreakdownRow}>
                  <Typography sx={orderDetailstyle.paymentType}>{payment.type}:</Typography>
                  <Typography sx={orderDetailstyle.paymentAmount}>{formatBalance(payment.amount)}</Typography>
                </Box>
              ))
            ) : (
              <Typography sx={orderDetailstyle.noPaymentsText}>No payment breakdown available.</Typography>
            )}
          </Box>

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.totalRow}>
            <Typography sx={orderDetailstyle.totalLabel}>TOTAL:</Typography>
            <Typography sx={orderDetailstyle.totalAmount}>{formatBalance(payment_amount)}</Typography>
          </Box>
        </Box>

        <Box style={{ marginTop: '50px', textAlign: 'right' }} sx={orderDetailstyle.summaryBox}>
          <Button style={{ marginRight: '5px' }} variant="contained" color="primary" onClick={() => setOpenAdjustWallet(true)}>
            Adjust Wallet
          </Button>
          <Button style={{ marginRight: '5px' }} variant="contained" color="primary" onClick={() => setOpenAdjustCreditCard(true)}>
            Adjust Credit Card
          </Button>
        </Box>
      </Container>

      <AdjustWallet
        open={openAdjustWallet}
        setOpen={setOpenAdjustWallet}
        updateAdjustWallet={_updateAdjustWallet}
      />

      <AdjustCreditCard
        open={openAdjustCreditCard}
        setOpen={setOpenAdjustCreditCard}
        updateAdjustCreditCard={_updateAdjustCreditCard}
      />
    </>
  );
};

export default OrderDetailsPage;
