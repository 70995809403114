import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataTableProps } from '../../../models/IDataTable';
import { Box, CircularProgress } from '@mui/material';

const DataTable: React.FC<DataTableProps> = ({ rows, columns, loading, getRowId, checkboxSelection = false }) => {

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[100]}
          checkboxSelection={checkboxSelection}
          sx={{
            backgroundColor: '#fff',
            boxShadow: 3,
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
            padding: 2
          }}
        />
      )}
    </Box>
  );
};

export default DataTable;