import { INewUser, IUser } from "../models/IUser";
import axiosInstance from "../api/axiosInstance";
import { PATH_NAME } from '../configs/pathName';
import { toast } from '../components/common/SnackBar/snackBar';
import { handleError } from '../helpers/Fetchers/fetchers';

export const Login = async (data: IUser) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_LOGIN, data);
        if (response.status === 200) {
            toast.success("Login successful!");
            return response.data;
        } else {
            toast.error("Unexpected status code: " + response.status);
            throw new Error("Unexpected status code: " + response.status);
        }
    } catch (error) {
        handleError(error,"Login API error:");
        toast.error("Login failed");
        throw error;
    }
};

export const Register = async (data: INewUser) => {
    try {
        const response = await axiosInstance.post(PATH_NAME.API_AUTH_REGISTER, data);
        if (response.status === 200) {
            toast.success("Registration successful!");
            return response.data;
        } else {
            toast.error("Unexpected status code: " + response.status);
            throw new Error("Unexpected status code: " + response.status);
        }
    } catch (error) {
        handleError(error,"Register API error:");
        toast.error("Registration failed");
        throw error;
    }
};

export const UserProfile = async () => {
    try {
        const response = await axiosInstance.get(PATH_NAME.API_AUTH_PROFILE);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Unexpected status code: " + response.status);
        }
    } catch (error) {
        handleError(error,"UserProfile API error:");
        throw error;
    }
};