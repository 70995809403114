import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CustomTabPanel from '../Tab/CustomTabPanel';

interface TabItem {
    label: string;
    content: React.ReactNode;
  }
  
  interface BasicTabsProps {
    tabs: TabItem[];
  }

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function BasicTabs({ tabs }: BasicTabsProps) {
    
    const [value, setValue] = React.useState(0);
    // const navigate = useNavigate();
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}