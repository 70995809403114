import { PATH_NAME } from "../configs/pathName";
import { IOrder } from "../models/IOrders";
import axiosInstance from "./axiosInstance";
import { handleError } from '../helpers/Fetchers/fetchers';
import { IPayment } from "../models/IPayments";

export const fetchPaymentsAPI = async (limit: number, offset: number, start_date?: string,
  end_date?: string): Promise<{ result: { items: { orders: IOrder[] } } }> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_PAYMENT_HISTORY, {
      params: { limit, offset, start_date, end_date },
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching orders:');
    throw error;
  }
};

export const getPaymentById = async (payment_id: string): Promise<IPayment> => {
  const response = await axiosInstance.get(PATH_NAME.API_GET_PAYMENT_ID(payment_id));
  return response.data.result;
};

export const getUserPaymentsAPI = async (user_id: number) => {
  const response = await axiosInstance.get(PATH_NAME.API_GET_PAYMENT_BY_USER_ID(user_id));
  return response.data;
};