import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Divider, Typography, Box, Grid, Button } from '@mui/material';
import { IPermission, IRole, RoleFormProps } from '../../../models/IRole';
import { fetchPermissionsAPI } from '../../../api/RoleAPI';

const RoleForm: React.FC<RoleFormProps> = ({ roleForm, onSave, onCancel, isLoading }) => {
  const [roleItem, setRoleItem] = useState<IRole>({ ...roleForm });
  const [permissionsList, setPermissionsList] = useState<IPermission[]>([]);

  useEffect(() => {
    setRoleItem(roleForm);
  }, [roleForm]);

  useEffect(() => {
    const fetchPermissionsList = async () => {
      try {
        const permissions = await fetchPermissionsAPI();
        setPermissionsList(permissions);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };
    fetchPermissionsList();
  }, []);

  const handlePermissionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const permissionId = parseInt(name);

    setRoleItem(prevState => {
      const newPermissions = checked
        ? [...prevState.permissions, permissionId]
        : prevState.permissions.filter(id => id !== permissionId);

      return { ...prevState, permissions: newPermissions };
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRoleItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    onSave(roleItem);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="subtitle1" fontWeight="bold">Role Name</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            name="name"
            label="Role Name"
            value={roleItem.name}
            onChange={handleChange}
            fullWidth
            size="small"
            margin="normal"
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Permissions</Typography>
      <Grid container spacing={2}>
        {permissionsList.map(permission => (
          <Grid item xs={6} sm={4} key={permission.id}>
            <FormControlLabel
              control={
                <Checkbox
                  name={permission.id.toString()}
                  checked={roleItem.permissions.includes(permission.id)}
                  onChange={handlePermissionChange}
                />
              }
              label={permission.name}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default RoleForm;