import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/Auth/AuthSlice";
import appStateSlice from "./features/appStateSlice";

export const store = configureStore({
    reducer: {
        appState: appStateSlice,
        authState: authReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;