import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { getUserByIdAPI } from '../../../../api/UserAPI';
import { IUserUpdateData } from '../../../../models/IUser';
import UserLayoutPage from './UserLayoutPage';
import UserGeneralInfo from './UserGeneralTab';
import UserOrders from './UserOrdersTab';
import UserPayments from './UserPaymentTab';
import { getStoreCreditByUserIdAPI } from '../../../../api/StoreCreditAPI';
import { IStoreCredit } from '../../../../models/IStoreCredit';
import UserRewards from './UserRewardTab';
import UserMessages from './UserMessagePage';
import StoreCreditTransaction from './component/UserWalletTab';
import UserCoupons from './component/UserCouponsTab';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUserUpdateData | null>(null);
  const [storeCredit, setStoreCredit] = useState<IStoreCredit | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [loadingStoreCredit, setLoadingStoreCredit] = useState<boolean>(true);
  const [errorUser, setErrorUser] = useState<string | null>(null);
  const [errorStoreCredit, setErrorStoreCredit] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoadingUser(true);
        const data = await getUserByIdAPI(id!);
        setUser(data);
      } catch (error) {
        setErrorUser(`Failed to fetch user with ID ${id}`);
      } finally {
        setLoadingUser(false);
      }
    };

    if (id) {
      fetchUser();
    }
  }, [id]);

  useEffect(() => {
    const fetchStoreCredit = async () => {
      try {
        setLoadingStoreCredit(true);
        const data = await getStoreCreditByUserIdAPI(Number(id));
        const storeCreditData = data.result.items[0];
        setStoreCredit(storeCreditData);
      } catch (error) {
        console.error("Error fetching store credit:", error);
        setErrorStoreCredit(`Failed to fetch store credit for user ID ${id}`);
      } finally {
        setLoadingStoreCredit(false);
      }
    };

    if (id) {
      fetchStoreCredit();
    }
  }, [id]);

  if (loadingUser || loadingStoreCredit) return <CircularProgress />;
  if (errorUser) return <Typography color="error">{errorUser}</Typography>;
  if (errorStoreCredit) return <Typography color="error">{errorStoreCredit}</Typography>;

  const tabs = [
    {
      label: 'General',
      content: (
        <UserGeneralInfo 
          user={user} 
          storeCredit={storeCredit} 
          userId={parseInt(id!, 10)} 
        />
      )
    },
    {
      label: 'Orders',
      content: <UserOrders userId={parseInt(id!, 10)} />
    },
    {
      label: 'Payments',
      content: <UserPayments userId={parseInt(id!, 10)} />
    },
    {
      label: 'Reward Points',
      content: <UserRewards userId={parseInt(id!, 10)} />
    },
    {
      label: 'Communication',
      content: <UserMessages userId={parseInt(id!, 10)} />
    },
    {
      label: 'Wallet', //wallet mean store_credit
      content: <StoreCreditTransaction userId={parseInt(id!, 10)} />
    },
    {
      label: 'Coupons',
      content: <UserCoupons userId={parseInt(id!, 10)} />
    },
  ];

  const title = 'User Details';
  const backLink = '/Users';

  return (
    <div>
      <UserLayoutPage title={title} backLink={backLink} tabs={tabs} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
      </Box>
    </div>
  );
};

export default UserDetails;
