import { Container, Typography, Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from 'swr';
import { orderDetailstyle } from "../../../styles/OrderDetails";

import { getPaymentById } from "../../../../api/PaymentAPI";
import React from "react";



const formatCurrency = (amount: number | undefined) => `$ ${(amount ?? 0).toFixed(2)}`;

const PaymentDetailsPage = () => {
  const { paymentId } = useParams<{ paymentId?: string }>();

  const { data: paymentDetails, error: paymentError } = useSWR(
    paymentId ? ['getPaymentById', paymentId] : null,
    () => paymentId ? getPaymentById(paymentId) : null
  );


  if (paymentError) return <div>Error loading payment details</div>;
  if (!paymentDetails) return <div>Loading payment details...</div>;


  const PaymentRawData = ({ data }: { data: { [key: string]: any } }) => {
    if (!data) {
      return <div>No payment details available</div>;
    }

    return (
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
        {Object.entries(data).map(([key, value]) => (
          <React.Fragment key={key}>
            <span style={{ fontWeight: 'bold', textAlign: 'left' }}>{key}</span>
            <span style={{ textAlign: 'right' }}>{value ?? '-'}</span>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <>
      <Container sx={orderDetailstyle.container}>
        <Typography variant="h6" sx={orderDetailstyle.title}>Payment DETAILS</Typography>

        <Box sx={orderDetailstyle.summaryBox}>
          <Box sx={orderDetailstyle.headerRow}>
            <Typography sx={orderDetailstyle.headerText}>Invoice_no</Typography>
            <Typography sx={orderDetailstyle.headerText}>{paymentDetails.invoice_no}</Typography>
          </Box>

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.subtotalRow}>
            <Typography sx={orderDetailstyle.headerText}>Type</Typography>
            <Typography sx={orderDetailstyle.headerText}>{paymentDetails.type}</Typography>
          </Box>


          <Box sx={orderDetailstyle.paymentBreakdownBox}>
            <PaymentRawData data={paymentDetails.raw_data} />
          </Box>

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.totalRow}>
            <Typography sx={orderDetailstyle.headerText}>Amount</Typography>
            <Typography sx={orderDetailstyle.totalAmount}>{formatCurrency(paymentDetails.amount)}</Typography>
          </Box>
        </Box>


      </Container>



    </>
  );
};

export default PaymentDetailsPage;
