import axiosInstance from '../api/axiosInstance';
import { handleError } from '../helpers/Fetchers/fetchers';
import { ICollection, ISkus } from '../models/IProduct';

const HQAPP_SKU_API = 'https://hq-app.shakesalad.com:8443/api/thirdparty/opencart/skus';
const HQAPP_COLLECTIONS_API = 'https://hq-app.shakesalad.com:8443/api/thirdparty/opencart/collections';

export const fetchProductsSkusAPI = async (): Promise<{ result: { items: ISkus[] } }> => {
  try {
    const response = await axiosInstance.get(HQAPP_SKU_API, {
      headers: {
        'api-key': '1ppN7yh3u89Byjr'
      }
    });

    const { data } = response.data;
    
    const mappedProducts = data.map((product: { id: number; name: string; active: boolean }) => ({
      id: product.id,
      name: product.name,
      active: product.active
    }));

    return { result: { items: mappedProducts } };
  } catch (error) {
    handleError(error, 'Error fetching products:');
    throw error;
  }
};

export const fetchCollectionsAPI = async (): Promise<{ result: { items: ICollection[] } }> => {
  try {
    const response = await axiosInstance.get(HQAPP_COLLECTIONS_API, {
      headers: {
        'api-key': '1ppN7yh3u89Byjr'
      }
    });

    const { data } = response.data;
    
    const mappedCollections = data.map((collection: { id: number; name: string; active: boolean; description?: string }) => ({
      id: collection.id,
      name: collection.name,
      description: collection.description,
      active: collection.active
    }));

    return { result: { items: mappedCollections } };
  } catch (error) {
    handleError(error, 'Error fetching collections:');
    throw error;
  }
};