import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useSWR from 'swr';
import { PATH_NAME } from '../../../configs/pathName';
import { generalFetcher, generalItemFetcher } from '../../../helpers/Fetchers/fetchers';
import DataTable from '../../common/DataTable/DataTable';

const RewardPointTable: React.FC = () => {
  const navigate = useNavigate();

  const { data: profile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: rewardPoints = [], error, isLoading } = useSWR(profile ? PATH_NAME.API_ADMIN_REWARD_POINT : null, generalItemFetcher);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'user_id', headerName: 'User ID', flex: 1 },
    { field: 'points', headerName: 'Points', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { field: 'balance', headerName: 'Balance', flex: 1 },
    { field: 'order_id', headerName: 'Order ID', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      field: 'transaction_at',
      headerName: 'Transaction Date',
      flex: 2,
      renderCell: (params:any) => {
        if (!params.value) return '';
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
    
        return formattedDate;
      },
    }
  ];

  const handleCreate = () => {
    navigate('/Marketing/RewardPoint/AddRewardPoint');
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Reward Points</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Add
        </Button>
      </Box>
      <DataTable
        rows={rewardPoints || []}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
        key={rewardPoints.length}
      />
    </div>
  );
};

export default RewardPointTable;
