import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { Button, MenuItem } from '@mui/material';
import { IRewardPoint, RewardPointFormProps } from '../../../models/IRewardPoint';

const RewardPointForm: React.FC<RewardPointFormProps> = ({ rewardPointForm, onSave, onCancel, isLoading }) => {
  const [rewardPoint, setRewardPoint] = React.useState<IRewardPoint>({ ...rewardPointForm });

  useEffect(() => {
    setRewardPoint(rewardPointForm);
  }, [rewardPointForm]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRewardPoint({
      ...rewardPoint,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    onSave(rewardPoint);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          User ID
        </div>
        <TextField
          name="user_id"
          label="User ID"
          value={rewardPoint.user_id}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          type="number"
          style={{ width: '85%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          Points
        </div>
        <TextField
          name="points"
          label="Points"
          value={rewardPoint.points}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          type="number"
          style={{ width: '85%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          Description
        </div>
        <TextField
          name="description"
          label="Description"
          value={rewardPoint.description}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          type="string"
          style={{ width: '85%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          Balance
        </div>
        <TextField
          name="balance"
          label="Balance"
          value={rewardPoint.balance}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          type="number"
          style={{ width: '85%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          Order ID
        </div>
        <TextField
          name="order_id"
          label="Order ID"
          value={rewardPoint.order_id}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          type="number"
          style={{ width: '85%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            marginRight: '20px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          Type
        </div>
        <TextField
          select
          name="type"
          label="Type"
          value={rewardPoint.type}
          onChange={handleChange}
          fullWidth
          size="small"
          margin="normal"
          style={{ width: '85%' }}
        >
          <MenuItem value="debit">Debit</MenuItem>
          <MenuItem value="credit">Credit</MenuItem>
        </TextField>
      </div>
      <Divider sx={{ my: 2 }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant="outlined" color="secondary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default RewardPointForm;