import React, { useState } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VoucherMemberGroup from '../../../common/VoucherForm/VoucherMemberGroupForm';
import { IMemberGroupCoupon } from '../../../../models/IMemberGroupCoupon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createMemberGroupCouponAPI } from '../../../../api/MemberGroupCouponAPI';
import { toast } from '../../../common/SnackBar/snackBar';

const CreateMemberGroupCoupon: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [memberGroupCouponForm, setMemberGroupCouponForm] = useState<IMemberGroupCoupon>({
    id: 0,
    is_deleted: false,
    member_group_id: 0,
    coupon_definition_id: 0,
    name: '',
    date_start: '',
    date_end: '',
    all_machines: false,
    applicable_machines: [],
    user_redemption_period_limit: 0,
    user_redemption_period_type: '',
    group_redemption_period_limit: 0,
    group_redemption_period_type: '',
  });
  
  const navigate = useNavigate(); 

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: IMemberGroupCoupon) => {
    setMemberGroupCouponForm(updatedForm);
  };

  const handleCancel = () => {
    navigate('/Marketing/MemberGroupCoupons');
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const couponToSave = {
        ...memberGroupCouponForm,
      };
      await createMemberGroupCouponAPI(couponToSave);
      toast.success('Member group coupon created successfully');
      navigate('/Marketing/MemberGroupCoupons');
    } catch (error) {
      toast.error('Error creating coupon');
    }
  };

  return (
    <Box>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      <h2>Create Member Group Coupons</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Voucher Tabs">
        <Tab label="Member Groups" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherMemberGroup
          memberGroupCouponForm={memberGroupCouponForm}
          onFormChange={handleFormChange}
          onCancel={handleCancel}
          isLoading={isLoading}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateMemberGroupCoupon;