import { Container, Box, Typography, TextField, Button, Paper, Grid } from '@mui/material';
import { Link} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { register } from '../../../redux/features/Auth/AuthThunk';
import ShakeSaladLogo from '../../../assets/images/shakesaladlogo.png';
import { useState } from "react";
import YearPicker from '../../../helpers/DatePicker/YearPicker';
import MonthPicker from '../../../helpers/DatePicker/MonthPicker';
import { Dayjs } from 'dayjs';
import CustomAlert from '../../../helpers/AlertMessage/CustomAlert';

const RegisterPage = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [birthYear, setBirthYear] = useState<Dayjs | null>(null);
    const [birthMonth, setBirthMonth] = useState<Dayjs | null>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
    
    const handleRegister = async () => {
        const phoneFormat = phone.match(/\d+/);
        const countryCode = phoneFormat ? '+' + phoneFormat[0].slice(0, phone.length - 10) : '';
        const phoneNumber = phoneFormat ? phoneFormat[0].slice(phoneFormat[0].length - 10) : '';

        if (phone && password && email && firstname && lastname && birthYear && birthMonth) {
            try {
                await dispatch(
                    register({
                        country_code: countryCode,
                        mobile: phoneNumber,
                        password,
                        email,
                        firstname,
                        lastname,
                        birth_year: birthYear.year(),
                        birth_month: birthMonth.month() + 1,
                    })
                ).unwrap();
                // navigate(PATH_NAME.ROOT);
                window.location.reload();
            } catch (error) {
                console.error(error);
                setAlertMessage("Registration failed");
                setAlertSeverity("error");
                setOpenAlert(true);
            }
        } else {
            setAlertMessage("Please fill in all fields.");
            setAlertSeverity("warning");
            setOpenAlert(true);
        }
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    return (
        <Box
            component="section"
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container maxWidth="xs">
                <Paper
                    elevation={0}
                    sx={{
                        p: 0,
                        borderRadius: 2,
                        backgroundColor: 'background.paper',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <img
                            alt="Company Logo"
                            src={ShakeSaladLogo}
                            style={{ width: 100 }}
                        />
                    </Box>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Create new Account
                    </Typography>
                    <Typography variant="body2" align="center" gutterBottom>
                        Already Registered? <Link to="/login">Log in here</Link>.
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            type="text"
                            value={firstname}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            type="text"
                            value={lastname}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            sx={{ mb: 2 }}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                        <PhoneInput
                            country={'sg'}
                            value={phone}
                            onChange={setPhone}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                            }}
                            containerStyle={{ width: '100%' }}
                            inputStyle={{ width: '100%' }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <YearPicker
                                    value={birthYear}
                                    onChange={setBirthYear}
                                    label="Birth Year"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MonthPicker
                                    value={birthMonth}
                                    onChange={setBirthMonth}
                                    label="Birth Month"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, backgroundColor: '#136936', color: '#fff', '&:hover': { backgroundColor: '#136936' }}}
                            onClick={handleRegister}
                        >
                            Sign up
                        </Button>
                    </Box>
                </Paper>
            </Container>
            <CustomAlert
                open={openAlert}
                message={alertMessage}
                severity={alertSeverity}
                onClose={handleCloseAlert}
            />
        </Box>
    );
};

export default RegisterPage;
