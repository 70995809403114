import React, { useState } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import { ICouponDefinitions } from '../../../../models/IVoucher';
import VoucherDefinitions from '../../../common/VoucherForm/VoucherDefinitionsForm';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../../common/SnackBar/snackBar';
import { createNewCouponDefinitionAPI } from '../../../../api/CouponDefinitionAPI';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CreateVoucherPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [voucherForm, setVoucherForm] = useState<ICouponDefinitions>({
    id: null,
    name: '',
    code: '',
    discount_type: '',
    discount_amount: 0,
    minimum_spending: 0,
    minimum_spending_active: true,
    criterial_cart_type: '',
    criterial_cart_skus: [],
    criterial_cart_collections: [],
    active: true,
    target_type: '',
    target_skus: [],
    target_collections: [],
    date_start: null,
    date_end: null,
    is_deleted: false,
  });
  const navigate = useNavigate();

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: ICouponDefinitions) => {
    setVoucherForm(updatedForm);
  };

  const handleCancel = () => {
    navigate('/Marketing/CouponDefinitions');
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const voucherToSave = {
        ...voucherForm,
        minimum_spending: voucherForm.minimum_spending === 0 ? null : voucherForm.minimum_spending,
      };
      await createNewCouponDefinitionAPI(voucherToSave);
      toast.success('Voucher created successfully');
      navigate('/Marketing/CouponDefinitions');
    } catch (error) {
      toast.error('Error creating voucher');
    }
  };

  return (
    <Box>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>     
      <h2>Create New Coupon Defintions</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Voucher Tabs">
        <Tab label="Definitions" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherDefinitions
            voucherForm={voucherForm}
            onFormChange={handleFormChange}
            isLoading={isLoading}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateVoucherPage;