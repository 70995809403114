import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { RewardPointInputField, iniRewardPointInputField } from './UserAddRewardPointDto';

interface RewardPointProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateRewardPoints: (inputField: RewardPointInputField) => void;
}

const UserRewardPoints: React.FC<RewardPointProps> = ({
    open,
    setOpen,
    updateRewardPoints
}) => {
    const [inputField, setInputField] = React.useState(iniRewardPointInputField());

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const _confirmUpdate = () => {
        updateRewardPoints(inputField);
        setInputField(iniRewardPointInputField());
        setOpen(false);
    };

    const _cancelUpdate = () => {
        setInputField(iniRewardPointInputField());
        setOpen(false);
    };

    const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.addEventListener('wheel', handleWheel as EventListener); // Attach wheel event on focus
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.removeEventListener('wheel', handleWheel as EventListener); // Remove wheel event on blur
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>{"Reward Points"}</DialogTitle>
            <DialogContent>
                <TextField
                    name="description"
                    label="Description"
                    value={inputField.description}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="string"
                    style={{ width: '100%' }}
                />
                <TextField
                    name="points"
                    label="Points"
                    value={inputField.points}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ width: '100%' }}
                    onFocus={handleFocus} // Add focus handler
                    onBlur={handleBlur}   // Add blur handler
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={_cancelUpdate} color="primary">
                    Cancel
                </Button>
                <Button onClick={_confirmUpdate} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserRewardPoints;
