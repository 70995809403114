import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { getUserMessagebyUserId } from '../../../../api/UserAPI';
import { UserMessagesProps, IUserMessage } from '../../../../models/IUser';

const UserMessages: React.FC<UserMessagesProps> = ({ userId }) => {
  const [messages, setMessages] = useState<IUserMessage[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoading(true);
        const data = await getUserMessagebyUserId(userId);
        setMessages(data);
      } catch (error) {
        setError(`Failed to fetch messages for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [userId]);


  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="user messages table">
        <TableHead>
          <TableRow>
            <TableCell>Message ID</TableCell>
            <TableCell align="right">Order ID</TableCell>
            <TableCell align="right">Message</TableCell>
            <TableCell align="right">Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages && messages.length > 0 ? (
            messages.map((message) => (
              <TableRow key={message.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{message.id}</TableCell>
                <TableCell align="right">{message.order_id}</TableCell>
                <TableCell align="right">{message.message}</TableCell>
                <TableCell align="right">{dayjs(message.created_at).format('DD-MM-YYYY HH:mm')}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No messages found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserMessages;
