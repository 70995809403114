export interface UserAdjustingInputField {
    amount: number;
    description: string;
}

export const AMOUNT_PROPERTY = 'amount'

export const iniUserAdjustingInputField = (): UserAdjustingInputField => {
    return {
        amount: 0,
        description: ''
    }
}