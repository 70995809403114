import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { getUserPaymentsAPI } from '../../../../api/PaymentAPI';
import { IPayment, UserPaymentsProps } from '../../../../models/IPayments';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

const UserPayments: React.FC<UserPaymentsProps> = ({ userId }) => {
  const [payments, setPayments] = useState<IPayment[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoading(true);
        const data = await getUserPaymentsAPI(userId);
        setPayments(data.result.items);
      } catch (error) {
        setError(`Failed to fetch payments for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, [userId]);

  const handleViewClick = (id: number) => {
    navigate(`/payment_details/${id}`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="user payments table">
        <TableHead>
          <TableRow>
            <TableCell>Payment ID</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Transaction ID</TableCell>
            <TableCell align="right">Invoice No</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Created At</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments && payments.length > 0 ? (
            payments.map((payment) => (
              <TableRow key={payment.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{payment.id}</TableCell>
                <TableCell align="right">{payment.amount}</TableCell>
                <TableCell align="right">{payment.transaction_id}</TableCell>
                <TableCell align="right">{payment.invoice_no}</TableCell>
                <TableCell align="right">{payment.type}</TableCell>
                <TableCell align="right">{dayjs(payment.created_at).format('DD-MM-YYYY HH:mm')}</TableCell>
                <TableCell align="right">
                  <IconButton aria-label="view" size="small" color="primary" onClick={() => handleViewClick(payment.id)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align="center">
                No payments found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserPayments;
