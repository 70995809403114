import { PATH_NAME } from "../configs/pathName";
import { StoreCreditResponse } from "../models/IStoreCredit";
import axiosInstance from "./axiosInstance";

export const getStoreCreditByUserIdAPI = async (user_id: number) => {
    const response = await axiosInstance.get(PATH_NAME.API_GET_STORE_CREDIT_BY_USER_ID(user_id));
    return response.data;
  };

export const getStoreCreditTransactionByUserIdAPI = async (user_Id: number): Promise<StoreCreditResponse> => {
    const response = await axiosInstance.get(PATH_NAME.API_GET_STORE_CREDIT_TRANSACTION_BY_USER_ID(user_Id));
    return response.data;
  };