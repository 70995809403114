import React, { ReactNode } from 'react';
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack';

let useSnackbarRef: any;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

interface SnackbarComponentProps {
  children: ReactNode;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ children }) => (
    <SnackbarProvider 
    maxSnack={3} 
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <SnackbarUtilsConfigurator />
    {children}
  </SnackbarProvider>
);

export const toast = {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};

export default SnackbarComponent;
