import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';
import { IMemberGroupCoupon } from '../models/IMemberGroupCoupon';

export const createMemberGroupCouponAPI = async (couponData: IMemberGroupCoupon): Promise<IMemberGroupCoupon> => {
    try {
      const response = await axiosInstance.post(PATH_NAME.API_MEMBER_GROUP_COUPONS, couponData);
      return response.data;
    } catch (error) {
      handleError(error, 'Error creating member group coupon:');
      throw error;
    }
};

export const deleteMemberGroupCouponAPI = async (member_group_coupon_id: number) => {
    try {
      await axiosInstance.delete(PATH_NAME.API_ADMIN_MEMBER_GROUP_COUPONS_BY_ID(member_group_coupon_id.toString()));
    } catch (error) {
      handleError(error, 'deleting member group coupon');
      throw new Error('Failed to delete member group coupon');
    }
};

export const getMemberGroupCouponByIdAPI = async (member_group_coupon_id: string): Promise<IMemberGroupCoupon> => {
    try {
      const response = await axiosInstance.get(PATH_NAME.API_ADMIN_MEMBER_GROUP_COUPONS_BY_ID(member_group_coupon_id), {
      });
      if (response.data.success) {
        return response.data.result;
      } else {
        throw new Error('Failed to fetch voucher');
      }
    } catch (error) {
      handleError(error,`Error fetching voucher with code ${member_group_coupon_id}:`);
      throw error;
    }
};

export const updateMemberGroupCouponAPI = async (member_group_coupon_id: string, membergroupcoupon: IMemberGroupCoupon): Promise<IMemberGroupCoupon> => {
    try {
      const response = await axiosInstance.put(PATH_NAME.API_ADMIN_MEMBER_GROUP_COUPONS_BY_ID(member_group_coupon_id), membergroupcoupon);
      return response.data;
    } catch (error) {
      handleError(error,'Error updating voucher:');
      throw error;
    }
  };
  