import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';
import { IMemberGroup } from '../models/IMemberGroups';

export const getMemberGroupAPI = async (id: string): Promise<IMemberGroup> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_MEMBER_GROUPS);
    if (response.data.success) {
      return response.data.result.items;
    } else {
      throw new Error('Failed to fetch Member Groups');
    }
  } catch (error) {
    handleError(error,`Error fetching Member Groups with id ${id}:`);
    throw error;  
  }
};

export const createNewMemberGroupsAPI = async (memberGroup: IMemberGroup): Promise<IMemberGroup> => {
    try {
      const response = await axiosInstance.post(PATH_NAME.API_ADMIN_MEMBER_GROUPS, memberGroup);
      if (response.data && response.data.result) {
        return response.data.result;
      } else {
        throw new Error('Failed to create voucher');
      }
    } catch (error) {
      handleError(error,'Error creating voucher:');
      throw error;
    }
};

export const getMemberGroupByIdAPI = async (id: string): Promise<IMemberGroup> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_MEMBER_GROUPS_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch Member Groups');
    }
  } catch (error) {
    handleError(error,`Error fetching Member Groups with id ${id}:`);
    throw error;  
  }
};  
  
export const updateMemberGroupsAPI = async (id: string, memberGroup: IMemberGroup): Promise<IMemberGroup> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_MEMBER_GROUPS_BY_ID(id), memberGroup);
    return response.data;
  } catch (error) {
    handleError(error,'Error updating Mmeber Groups:');
    throw error;
  }
};

export const deleteMemberGroupsAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_MEMBER_GROUPS_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, 'deleting voucher');
    throw new Error('Failed to delete voucher');
  }
};