export const orderDetailstyle = {
    container: {
      mt: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 0,
    },
    title: {
      mb: '20px',
      fontWeight: 'bold',
    },
    summaryBox: {
      width: '100%',
      maxWidth: '600px',
      p: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    headerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      mb: '15px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '16px',
      color: '#000',
    },
    itemRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      mb: '10px',
    },
    itemDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    itemName: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    itemQuantity: {
      fontSize: '12px',
      color: '#888',
    },
    itemPrice: {
      fontSize: '14px',
      color: '#4CAF50', // green color for price
    },
    subtotalRow: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: '10px',
    },
    subtotalText: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    paymentRow: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: '10px',
    },
    paymentLabel: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    paymentText: {
      fontSize: '14px',
    },
    totalRow: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: '10px',
      fontWeight: 'bold',
    },
    totalLabel: {
      fontSize: '16px',
    },
    totalAmount: {
      fontSize: '16px',
      color: '#4CAF50', // green color for total
    },
    divider: {
      my: '15px',
    },
    noItemsText: {
      fontSize: '14px',
      color: '#888',
    },
    paymentBreakdownBox: {
      marginTop: 2,
      paddingTop: 1,
      borderTop: '1px solid #ddd',
    },
    breakdownTitle: {
      fontWeight: 'bold',
      marginBottom: 1,
    },
    paymentBreakdownRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 0.5,
    },
    paymentType: {
      color: 'gray',
    },
    paymentAmount: {
      fontWeight: 'bold',
    },
    noPaymentsText: {
      fontStyle: 'italic',
      color: 'gray',
    },
  };
  