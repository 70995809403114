import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import RoleForm from '../../../common/RoleForm/RoleForm';
import { IRole } from '../../../../models/IRole';
import { toast } from '../../../common/SnackBar/snackBar';
import { createRoleAPI } from '../../../../api/RoleAPI';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const defaultRole: IRole = {
  id: 0,
  name: '',
  permissions: [],
  tenant_id: 0,
  is_admin: false,
  is_hq_admin: false,
  is_deleted: false,
  deleted_at: null,
  created_by: 0,
  updated_by: 0,
  deleted_by: null,
  created_at: '',
  updated_at: '',
};


const AddRole: React.FC = () => {
  const [roleForm] = useState<IRole>(defaultRole);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = async (role: IRole) => {
    setIsLoading(true);
    try {
      await createRoleAPI({ name: role.name, permissions: role.permissions });
      toast.success('Role created successfully');
      navigate('/System/Roles');
    } catch (error) {
      toast.error('Error creating role');
    }
  };

  const handleCancel = () => {
    navigate('/System/Roles');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      
      <h2>Create</h2>
      <Divider sx={{ my: 2 }} />
      <RoleForm 
      roleForm={roleForm} 
      onSave={handleSave} 
      onCancel={handleCancel}
      isLoading={isLoading}
      />
    </div>
  );
};

export default AddRole;