import axiosInstance from '../api/axiosInstance';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';
import { IRewardPoint } from '../models/IRewardPoint';

export const fetchRewardPointsAPI = async (): Promise<{ result: IRewardPoint[] }> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_REWARD_POINT);
    return response.data;
  } catch (error) {
    handleError(error,'Error fetching users:');
    throw error;
  }
};

export const createRewardPointsAPI = async (newRewardPoint: Partial<IRewardPoint>) => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_REWARD_POINT, newRewardPoint);
    return response.data;
  } catch (error) {
    handleError(error, 'creating role');
    throw error;
  }
};

export const getRewardPointsByIdAPI = async (id: string): Promise<IRewardPoint> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_REWARD_POINT_BY_ID(id));
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch user');
    }
  } catch (error) {
    handleError(error,`Error fetching user with ID ${id}:`);
    throw error;
  }
};

export const getUserRewardsAPI = async (user_id: number) => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_GET_REWARD_POINTS_BY_USER_ID(user_id));
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching reward points:');
    throw error;
  }
};

export const getRewardPointBalance = async (user_id: number) => {
  try {
      const response = await axiosInstance.get(PATH_NAME.API_GET_USER_REWARD_POINT_BALANCE(user_id));
      if (response.data && response.data.result !== undefined) {
          return response.data.result;
      } else {
          console.error('Invalid response structure:', response.data);
          throw new Error('Invalid response structure');
      }
  } catch (error) {
      handleError(error, 'fetching reward point balance');
      throw new Error('Failed to fetch reward point balance');
  }
};

export const userAdjustRewardPointAPI = async (payload: {
  user_id: string | undefined,
  points: number,
  description: string
}) => {
  try {
    const response = await axiosInstance.post(`${PATH_NAME.API_ADMIN_REWARD_POINT}`, payload);
    return response.data;
  } catch (error) {
    handleError(error, 'Reward Points fail');
    throw error;
  }
};