import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Button, Divider } from '@mui/material';
import { ICouponDefinitions } from '../../../../models/IVoucher';
import VoucherDefinitions from '../../../common/VoucherForm/VoucherDefinitionsForm';
import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { toast } from '../../../common/SnackBar/snackBar';
import { getCoupOnDefinitionByIdAPI, updateCouponDefinitionsAPI } from '../../../../api/CouponDefinitionAPI';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EditVoucherPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { data: voucher, error } = useSWR(
    id ? `${PATH_NAME.API_ADMIN_COUPON_DEFINITION_BY_ID(id)}` : null,
    () => getCoupOnDefinitionByIdAPI(id!)
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [voucherForm, setVoucherForm] = useState<ICouponDefinitions>({
    id: 0,
    name: '',
    code: '',
    discount_type: '',
    discount_amount: 0,
    minimum_spending: 0,
    minimum_spending_active: true,
    criterial_cart_type: '',
    criterial_cart_skus: [],
    criterial_cart_collections: [],
    active: true,
    target_type: '',
    target_skus: [],
    target_collections: [],
    date_start: null,
    date_end: null,
    is_deleted: false,
  });

  useEffect(() => {
    if (voucher) {
      setVoucherForm(voucher);
    }
  }, [voucher]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleFormChange = (updatedForm: ICouponDefinitions) => {
    setVoucherForm(updatedForm);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (voucherForm.id) {
        await updateCouponDefinitionsAPI(voucherForm.id.toString(), voucherForm);
        toast.success('Voucher updated successfully');
        navigate('/Marketing/CouponDefinitions');
      } else {
        toast.error('Voucher ID is missing!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    navigate('/Marketing/CouponDefinitions');
  };

  if (error) {
    return <div>Error loading voucher data</div>;
  }

  return (
    <Box>
<Button
        onClick={handleCancel}
        startIcon={<ArrowBackIosIcon />}
        sx={{
          borderWidth: '1px',
          borderRadius: '30px',
          borderStyle: 'solid',
          padding:'5px 20px'
        }}
      >
        BACK
      </Button>      <h2>Edit Voucher</h2>
      <Divider sx={{ my: 2 }} />
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Voucher Tabs">
        <Tab label="Definitions" />
      </Tabs>
      <Box mt={2}>
        {tabIndex === 0 && (
          <VoucherDefinitions
            voucherForm={voucherForm}
            onFormChange={handleFormChange}
            isLoading={isLoading}  
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditVoucherPage;