import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IUserCoupon, VoucherUserCouponProps } from '../../../models/IUserCoupon';
import { ICouponDefinitions } from '../../../models/IVoucher';
import useSWR from 'swr';
import { getCoupOnDefinitionAPI } from '../../../api/CouponDefinitionAPI';
import { PATH_NAME } from '../../../configs/pathName';

const VoucherUserCoupon: React.FC<VoucherUserCouponProps> = ({
  userCouponForm,
  onFormChange, 
}) => {
  const [userCouponItem, setUserCouponItem] = useState<IUserCoupon>(userCouponForm);
  const [selectedRedemptionPeriodType, setSelectedRedemptionPeriodType] = useState<string>(userCouponForm.user_redemption_period_type || '');
  const [selectedCouponDefinition, setSelectedCouponDefinition] = useState<number | ''>(userCouponItem.coupon_definition_id || '');

  const { data: couponDefinitionsData } = useSWR(PATH_NAME.API_ADMIN_COUPON_DEFINITION, getCoupOnDefinitionAPI);

  useEffect(() => {
    setUserCouponItem(userCouponForm);
    setSelectedCouponDefinition(userCouponForm.coupon_definition_id || '');
    setSelectedRedemptionPeriodType(userCouponForm.user_redemption_period_type || '');
  }, [userCouponForm]);

  useEffect(() => {
    if (couponDefinitionsData && userCouponForm && userCouponForm.coupon_definition_id) {
      setSelectedCouponDefinition(userCouponForm.coupon_definition_id);
    }
  }, [couponDefinitionsData, userCouponForm]);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    const updatedUserCoupon = {
      ...userCouponItem,
      [name]: type === 'checkbox' ? checked : value,
    };
    setUserCouponItem(updatedUserCoupon);
    onFormChange(updatedUserCoupon);
  };

  const handleCouponDefinitionChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setSelectedCouponDefinition(value);
    const updatedUserCoupon = {
      ...userCouponItem,
      coupon_definition_id: value,
    };
    setUserCouponItem(updatedUserCoupon);
    onFormChange(updatedUserCoupon);
  };

  const handleRedemptionPeriodTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedRedemptionPeriodType(value);
    const updatedUserCoupon = {
      ...userCouponItem,
      user_redemption_period_type: value,
    };
    setUserCouponItem(updatedUserCoupon);
    onFormChange(updatedUserCoupon);
  };

  const couponList = Array.isArray(couponDefinitionsData) ? couponDefinitionsData : [];
  
  const periodTypes = ['all', 'year', 'month', 'week', 'day'];

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
      <Grid item xs={2} container justifyContent="flex-end">
        <Typography variant="subtitle2" fontWeight="bold">Coupon Definition</Typography>
      </Grid>
      <Grid item xs={10}>
      <FormControl fullWidth margin="normal" size="small">
        <InputLabel id="coupon-definition-label">Coupon Definition</InputLabel>
          <Select
          labelId="coupon-definition-label"
          label="Coupon Definition"
          value={selectedCouponDefinition || ''}
          onChange={handleCouponDefinitionChange}
        >
          {couponList.map((couponDefinition: ICouponDefinitions) => (
            <MenuItem key={couponDefinition.id} value={couponDefinition.id ?? ''}>
              {couponDefinition.name} (ID: {couponDefinition.id})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
    </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Coupon Name</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="name"
            label="Coupon Name"
            value={userCouponItem.name || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">User Id</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="user_id"
            label="User Id"
            value={userCouponItem.user_id || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Start Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date_start"
            label="Start Date"
            type="date"
            value={userCouponItem.date_start || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">End Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="date_end"
            label="End Date"
            type="date"
            value={userCouponItem.date_end || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Apply to All Machines</Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControlLabel
            control={
              <Checkbox
                name="all_machines"
                checked={userCouponItem.all_machines || false}
                onChange={handleChange}
              />
            }
            label="Active"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Redemption Limit</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="user_redemption_period_limit"
            label="Redemption Limit"
            value={userCouponItem.user_redemption_period_limit || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">User Redemption Period Type</Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="user-redemption-period-type-label">Redemption Period Type</InputLabel>
            <Select
              labelId="user-redemption-period-type-label"
              value={selectedRedemptionPeriodType || ''}
              onChange={handleRedemptionPeriodTypeChange}
              label="Redemption Period Type"
            >
              {periodTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VoucherUserCoupon;