import axiosInstance from './axiosInstance';
import { ICouponDefinitions } from '../models/IVoucher';
import { PATH_NAME } from '../configs/pathName';
import { handleError } from '../helpers/Fetchers/fetchers';

export const getCoupOnDefinitionAPI = async (id: string): Promise<ICouponDefinitions> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_COUPON_DEFINITION, {
    });
    if (response.data.success) {
      return response.data.result.items;
    } else {
      throw new Error('Failed to fetch voucher');
    }
  } catch (error) {
    handleError(error,`Error fetching voucher with code ${id}:`);
    throw error;
  }
};

export const getCoupOnDefinitionByIdAPI = async (id: string): Promise<ICouponDefinitions> => {
  try {
    const response = await axiosInstance.get(PATH_NAME.API_ADMIN_COUPON_DEFINITION_BY_ID(id), {
    });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw new Error('Failed to fetch voucher');
    }
  } catch (error) {
    handleError(error,`Error fetching voucher with code ${id}:`);
    throw error;
  }
};

export const updateCouponDefinitionsAPI = async (id: string, voucher: ICouponDefinitions): Promise<ICouponDefinitions> => {
  try {
    const response = await axiosInstance.put(PATH_NAME.API_ADMIN_COUPON_DEFINITION_BY_ID(id), voucher);
    return response.data;
  } catch (error) {
    handleError(error,'Error updating voucher:');
    throw error;
  }
};

export const createNewCouponDefinitionAPI = async (voucher: ICouponDefinitions): Promise<ICouponDefinitions> => {
  try {
    const response = await axiosInstance.post(PATH_NAME.API_ADMIN_COUPON_DEFINITION, voucher);
    if (response.data && response.data.success && response.data.result) {
      return response.data.result;
    } else {
      throw new Error('Failed to create voucher');
    }
  } catch (error) {
    handleError(error, 'Error creating voucher:');
    throw error;
  }
};

export const deleteCouponDefinitionAPI = async (id: number) => {
  try {
    await axiosInstance.delete(PATH_NAME.API_ADMIN_COUPON_DEFINITION_BY_ID(id.toString()));
  } catch (error) {
    handleError(error, 'deleting voucher');
    throw new Error('Failed to delete voucher');
  }
};