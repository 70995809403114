import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, MenuItem, Select, FormControl, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, SelectChangeEvent, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { ICouponDefinitions, VoucherDefinitionsProps } from '../../../models/IVoucher';
import { toast } from '../SnackBar/snackBar';
import { fetchCollectionsAPI, fetchProductsSkusAPI } from '../../../api/SkusAPI';
import DeleteIcon from '@mui/icons-material/Delete';

const VoucherDefinitions: React.FC<VoucherDefinitionsProps> = ({
  voucherForm,
  onFormChange,
}) => {
  const [voucherItem, setVoucherItem] = useState<ICouponDefinitions>({
    ...voucherForm,
    id: voucherForm.id || null,
    criterial_cart_type: voucherForm.criterial_cart_type || 'none',
    criterial_cart_skus: voucherForm.criterial_cart_skus || [],
    criterial_cart_collections: voucherForm.criterial_cart_collections || [],
    target_type: voucherForm.target_type || 'none',
    target_skus: voucherForm.target_skus || [],
    target_collections: voucherForm.target_collections || [],
  });

  const [skus, setSkus] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);

  const [selectedCartCriteriaSkus, setSelectedCartCriteriaSkus] = useState<string[]>([]);
  const [selectedCartCriteriaCollections, setSelectedCartCriteriaCollections] = useState<string[]>([]);

  const [selectedTargetSkus, setSelectedTargetSkus] = useState<string[]>([]);
  const [selectedTargetCollections, setSelectedTargetCollections] = useState<string[]>([]);

  useEffect(() => {
    setVoucherItem({
      ...voucherForm,
      id: voucherForm.id || null,
      minimum_spending: voucherForm.minimum_spending || null,
      criterial_cart_skus: voucherForm.criterial_cart_skus || [],
      criterial_cart_collections: voucherForm.criterial_cart_collections || [],
      target_skus: voucherForm.target_skus || [],
      target_collections: voucherForm.target_collections || [],  
    });
    setSelectedCartCriteriaSkus(voucherForm.criterial_cart_skus.map(String));
    setSelectedCartCriteriaCollections(voucherForm.criterial_cart_collections.map(String));
    setSelectedTargetSkus(voucherForm.target_skus.map(String));
    setSelectedTargetCollections(voucherForm.target_collections.map(String));

  }, [voucherForm]);  

  useEffect(() => {
    const fetchSKUsAndCollections = async () => {
      try {
        const productsResponse = await fetchProductsSkusAPI();
        setSkus(productsResponse.result.items);

        const collectionsResponse = await fetchCollectionsAPI(); 
        setCollections(collectionsResponse.result.items);

      } catch (error) {
        toast.error('Error fetching SKUs or collections');
      }
    };

    fetchSKUsAndCollections();
  }, []);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
  
    let updatedValue;
  
    if (name === 'minimum_spending' && (value === '0' || value === null)) {
      updatedValue = null;
    } else if (
      name === 'criterial_cart_skus' ||
      name === 'target_skus' ||
      name === 'criterial_cart_collections' ||
      name === 'target_collections'
    ) {
      updatedValue = value.split(',').map((item) => item.trim());
    } else {
      updatedValue = type === 'checkbox' ? checked : value;
    }
  
    const updatedVoucher = {
      ...voucherItem,
      [name]: updatedValue,
    };
  
    setVoucherItem(updatedVoucher);
    onFormChange(updatedVoucher);
  };  

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    handleFormUpdate({ [name]: value });
  };  

  const handleFormUpdate = (updatedFields: Partial<ICouponDefinitions>) => {
    const updatedVoucher = { ...voucherItem, ...updatedFields };
    setVoucherItem(updatedVoucher);
    onFormChange(updatedVoucher);
  };

  const handleCartCriteriaSkusSelect = (newValue: any[]) => {
    const value = newValue.map((product) => String(product.id));
    setSelectedCartCriteriaSkus(value);
    handleFormUpdate({ criterial_cart_skus: value.map(Number) });
  };

  const handleCartCriteriaCollectionSelect = (newValue: any[]) => {
    const value = newValue.map((collections) => String(collections.id));
    setSelectedCartCriteriaCollections(value);
    handleFormUpdate({ criterial_cart_collections: value.map(Number) });
  };

  const handleCartCriteriaSkusDelete = (productId: string) => {
    const updatedSelectedProducts = selectedCartCriteriaSkus.filter((id) => id !== productId);
    setSelectedCartCriteriaSkus(updatedSelectedProducts);
    handleFormUpdate({ criterial_cart_skus: updatedSelectedProducts.map(Number) });
  };

  const handleCartCriteriaCollectionsDelete = (collectionId: string) => {
    const updatedSelectedCollections = selectedCartCriteriaCollections.filter((id) => id !== collectionId);
    setSelectedCartCriteriaCollections(updatedSelectedCollections);
    handleFormUpdate({ criterial_cart_collections: updatedSelectedCollections.map(Number) });
  };

  const handleTargetSkusSelect = (newValue: any[]) => {
    const value = newValue.map((product) => String(product.id));
    setSelectedTargetSkus(value);
    handleFormUpdate({ target_skus: value.map(Number) });
  };

  const handleTargetCollectionSelect = (newValue: any[]) => {
    const value = newValue.map((collections) => String(collections.id));
    setSelectedTargetCollections(value);
    handleFormUpdate({ target_collections: value.map(Number) });
  };

  const handleTargetSkusDelete = (productId: string) => {
    const updatedSelectedProducts = selectedTargetSkus.filter((id) => id !== productId);
    setSelectedTargetSkus(updatedSelectedProducts);
    handleFormUpdate({ target_skus: updatedSelectedProducts.map(Number) });
  };

  const handleTargetCollectionsDelete = (collectionId: string) => {
    const updatedSelectedCollections = selectedTargetCollections.filter((id) => id !== collectionId);
    setSelectedTargetCollections(updatedSelectedCollections);
    handleFormUpdate({ target_collections: updatedSelectedCollections.map(Number) });

  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">
            Voucher Name
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="name"
            label="Voucher Name"
            value={voucherItem.name}
            onChange={handleChange}
            fullWidth
            size="small"
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">
            Voucher Code
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="code"
            label="Voucher Code"
            value={voucherItem.code}
            onChange={handleChange}
            fullWidth
            size="small"
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">
            Discount Type
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel>Discount Type</InputLabel>
            <Select
              name="discount_type"
              value={voucherItem.discount_type}
              onChange={handleSelectChange}
              label="Discount Type"
            >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="absolute">Absolute</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">
            Discount Amount
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="discount_amount"
            label="Discount Amount"
            value={voucherItem.discount_amount}
            onChange={handleChange}
            fullWidth
            size="small"
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">
            Minimum Spending Active
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControlLabel
            control={
              <Checkbox
                name="minimum_spending_active"
                checked={voucherItem.minimum_spending_active}
                onChange={handleChange}
              />
            }
            label="Active"
          />
        </Grid>
      </Grid>
      {voucherItem.minimum_spending_active && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2} container justifyContent="flex-end">
            <Typography variant="subtitle2" fontWeight="bold">
              Minimum Spending
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="minimum_spending"
              value={voucherItem.minimum_spending}
              onChange={handleChange}
              fullWidth
              size="small"
              margin="normal"
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Cart Criteria</Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="cart-criteria-label">Cart Criteria</InputLabel>
            <Select
              labelId="cart-criteria-label"
              name="criterial_cart_type"
              value={voucherItem.criterial_cart_type}
              label="Cart Criteria"
              onChange={handleSelectChange}
              >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="skus">Skus</MenuItem>
              <MenuItem value="collection">Collection</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {voucherItem.criterial_cart_type === 'skus' && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography variant="subtitle2" fontWeight="bold">Criteria Cart SKUs</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal" size="small">
                <Autocomplete
                  multiple
                  options={skus.sort((a, b) => {
                    if (a.name && b.name) {
                      return a.name.localeCompare(b.name);
                    }
                    return a.sku ? -1 : 1;
                  })}
                  getOptionLabel={(option) => `${option.name}`}
                  value={selectedCartCriteriaSkus.map((id) => skus.find((product) => product.id === Number(id)) || { id: 0, sku: '', name: '' })}
                  onChange={(_event, newValue) => handleCartCriteriaSkusSelect(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Product" placeholder="" size="small" fullWidth />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '90%' }}>Product Name</TableCell>
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCartCriteriaSkus.map((productId) => {
                      const product = skus.find((p) => p.id === Number(productId));
                      return (
                        product && (
                          <TableRow key={productId}>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleCartCriteriaSkusDelete(productId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}

      {voucherItem.criterial_cart_type === 'collection' && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography variant="subtitle2" fontWeight="bold">Criteria Cart Collections</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal" size="small">
                <Autocomplete
                  multiple
                  options={collections.sort((a, b) => {
                    if (a.name && b.name) {
                      return a.name.localeCompare(b.name);
                    }
                    return a.name ? -1 : 1;
                  })}
                  getOptionLabel={(collections) => `${collections.name}`}
                  value={selectedCartCriteriaCollections.map((id) =>
                    collections.find((collections) => collections.id === Number(id)) || { id: 0, name: '' }
                  )}
                  onChange={(_event, newValue) => handleCartCriteriaCollectionSelect(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Collection" placeholder="" size="small" fullWidth />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '90%' }}>Collection Name</TableCell>
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCartCriteriaCollections.map((collectionId) => {
                      const collection = collections.find((c) => c.id === Number(collectionId));
                      return (
                        collection && (
                          <TableRow key={collectionId}>
                            <TableCell>{collection.name}</TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleCartCriteriaCollectionsDelete(collectionId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight="bold">Target Type</Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="target-type-label">Target Type</InputLabel>
            <Select
              labelId="target-type-label"
              name="target_type"
              value={voucherItem.target_type}
              label="Target type"
              onChange={handleSelectChange}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="skus">Skus</MenuItem>
              <MenuItem value="collection">Collection</MenuItem>
              <MenuItem value="cart">Cart</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {voucherItem.target_type === 'skus' && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography variant="subtitle2" fontWeight="bold">Target Type SKUs</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal" size="small">
                <Autocomplete
                  multiple
                  options={skus.sort((a, b) => {
                    if (a.name && b.name) {
                      return a.name.localeCompare(b.name);
                    }
                    return a.sku ? -1 : 1;
                  })}
                  getOptionLabel={(option) => `${option.name}`}
                  value={selectedTargetSkus.map((id) => skus.find((product) => product.id === Number(id)) || { id: 0, sku: '', name: '' })}
                  onChange={(_event, newValue) => handleTargetSkusSelect(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Product" placeholder="" size="small" fullWidth />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '90%' }}>Product Name</TableCell>
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTargetSkus.map((productId) => {
                      const product = skus.find((p) => p.id === Number(productId));
                      return (
                        product && (
                          <TableRow key={productId}>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleTargetSkusDelete(productId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}

      {voucherItem.target_type === 'collection' && (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography variant="subtitle2" fontWeight="bold">Target Type Collections</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth margin="normal" size="small">
                <Autocomplete
                  multiple
                  options={collections.sort((a, b) => {
                    if (a.name && b.name) {
                      return a.name.localeCompare(b.name);
                    }
                    return a.name ? -1 : 1;
                  })}
                  getOptionLabel={(collections) => `${collections.name}`}
                  value={selectedTargetCollections.map((id) =>
                    collections.find((collections) => collections.id === Number(id)) || { id: 0, name: '' }
                  )}
                  onChange={(_event, newValue) => handleTargetCollectionSelect(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Collection" placeholder="" size="small" fullWidth />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '90%' }}>Collection Name</TableCell>
                      <TableCell style={{ width: '10%' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTargetCollections.map((collectionId) => {
                      const collection = collections.find((c) => c.id === Number(collectionId));
                      return (
                        collection && (
                          <TableRow key={collectionId}>
                            <TableCell>{collection.name}</TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => handleTargetCollectionsDelete(collectionId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" mt={2}>
      </Box>
    </Box>
  );
};

export default VoucherDefinitions;